.ideacion__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  .logo {
    width: 10rem;
    margin: 2em 3em;
  }

  h2 {
    padding-right: 5em;
    margin-top: 2em;
    font-weight: 800;
  }
  .time {
    display: flex;
    margin-right: 2em;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  

    p {
      color: $magenta-ligh-crush;
      font-weight: 600;
      font-size: x-large;
      font-family: "Futura Std Bold";
    }
  }
}
.ideacion {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;

  &__container {
    text-align: center;
    width: 100%;
    max-width: 700px;
    margin: auto;
  }
  &__error {
    color: $fucsia-crush;
    text-decoration: none;
  }

  &__logo {
    width: 26rem;
  }
  &__inputtext {
    font-family: "Futura Std Medium";
    display: flex;
    max-width: 500px;
    min-width: 300px;
    width: 100%;
    color: #000;
    border: none;
    outline: none;
  }
  &__length{
    width: 100%;
    text-align: right;
    color: $fucsia-crush;
  }
  &__textnew {
    background-color: $magente-light-2;
    border-radius: 1em;
    line-height: normal;
    width: 100%;
    padding: 1rem 3rem;
    margin-bottom: 2rem;    
    p {
      color: $azul-crush;
      font-size: 20px;
      font-family: "Futura Std Bold";
      word-wrap: break-word;
    }
  }
  &__input {
    display: flex;
    flex-direction: row;
    padding: 16px;
    flex: 1;
    margin: 15px 0;
    background: #ffffff;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    color: #888;
  }

  &__subtitle {
    color: $azul-crush;
    font-size: 1.1rem;
    padding-bottom: 1em;
    font-family: "Futura Std Heavy";
    width: 100%;
  }

  &__grid_content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.2em;
    margin: 2.5em 0;
  }
  &__reto {
    color: $azul-crush;
    font-family: "Futura Std Light";
  }
  &__card {
    background-color: $white;
    border-radius: 1em;
    padding: 1em 5em;
    &_flex {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      h3 {
        margin: 0;
        padding: 0;
      }
    }
  }

  &__input {
    display: flex;
    flex-direction: column;
    background: $white;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    color: #888;
    margin: 1rem 0;

    ::placeholder {
      color: $gris;
      font-family: "Futura Std Heavy";
      letter-spacing: 0.5px;
      font-size: 16px;
      padding: 0.8em 0 0 0em;
    }
  }
  &__label {
    font-size: 17px;
    line-height: 17px;
    font-family: "Futura Std Bold";
    margin-bottom: 10px;
    color: $magenta-ligh-crush;
    align-self: baseline;
  }
  &__textarea {
    font-size: 14px;
    line-height: 16px;
    font-family: "Futura Std Bold";
    margin-bottom: 10px;
    border: none;
    resize: none;
    min-width: 100%;
  }
  &__title_head {
    color: $gris;
    font-family: "Futura Std Bold";
  }

  &__title {
    color: $blacked;
    font-family: 'Futura Std Light';
    padding: 0 3rem;
    margin-bottom: 2rem;
    b {
      font-weight: bold;
      font-family: "Futura Std Bold";
    }
  }
  &__frase{
    margin-bottom: 5em;
  }
  &__button {
    @include button($fucsia-crush, $white);
  }
  &__content_button {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  &__change {
    @include button($variables-1, #794608);
    width: fit-content;
    height: min-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 10px 20px;
    font-family: "Futura Std Bold";
    margin-bottom: 2em;
    img{
      width: 28px;
      height: 28px;
    }
  }

  &__button:hover,
  &__change:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px -0.15rem 0px 0px inset;
    transform: translateY(1px);
  }

  @media (max-width: 580px) {
    &__input {
      width: 100%;
      padding: 0;
      margin: 0 auto;
      height: auto;
    }

    &__label {
      align-self: auto;
    }
  }
}
