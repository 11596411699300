// colors native
$white: #ffffff;
$gris : #888888;
$blacked: #0F0348;

// colors

$azul-crush: #391197;
$magenta-crush: #9C0A59;
$fucsia-crush: #FC1158;
$naranja-crush: #FD551F;
$amarillo-crush: #FEBC2C;
$beige-crush: #FDF0D5;
$background-color: #f3f3f3;
$magenta-ligh-crush: #9368DF;
$magenta : #6A40C0;
$fucsia-light: #FE6F7F;
$fucsia-light-2: #BF9DF4;
$magente-light-2: #E0CDF9;
$progress-bar : #4E26AB;
$variables-1 : #FEF7D4;
$variables-2: #DDFEFD;
$time-number: #151433;
$azul-crush-2: #210A56;
$disable-button: #E8E8E8;