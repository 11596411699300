.variables{

  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 5rem;
  
  &__container{
      text-align: center;
  }

  &__logo{
    width: 26rem;
  }
  &__inputtext{
    font-family: 'Futura Std Medium';
    display: flex;
    max-width: 500px;
    min-width: 300px;
    width: 100%;
    color: #000;
    border: none;

    
  }
  &__agregar {
    color : $azul-crush;
    font-family: 'Futura Std Bold';
    background-color: transparent;
    border: transparent;
    cursor: pointer;
  }
  

  &__subtitle {
    color : $azul-crush;
    font-size: 1.1rem;
    padding-bottom: 1em ;
    font-family: 'Futura Std Heavy';
    width: 100%;
  }

  &__reto{
    font-family: 'Futura Std Book';
    color: $magenta;
  }

  &__input {
    display: flex;
    flex-direction: row;
    padding: 10px 16px;
    flex: 1;
    margin: 15px 0;
    background: #FFFFFF;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    color: #888;
  }
  &__grid__variables{
    display: grid;
    grid-template-columns: repeat(3,minmax(200px, 1fr));
    gap: 1rem 2rem;
    margin: 2em 0;
  }

  &__card{
    padding: 0.5em;
    border-radius: 0.5em;
    display: flex;
    justify-content: center;


    input {
      border: none;
    }
  }
  &__content_input{

    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__count{
    color: $magenta;
    padding-left:0.8em ;
  }

  &__title {
    width: 100%;
    color : $gris;
  }

  &__button {
    @include button($fucsia-crush, $white);  
  }

  &__button:hover{
    box-shadow: rgba(0, 0, 0, 0.15) 0px -0.15rem 0px 0px inset;
    transform: translateY(1px);
  }

  @media (max-width : 580px ){
    &__input {
     
     width: 100%;
     padding: 0;
     margin: 0 auto;
     height: 40px;
    }

    &__label{
      align-self: auto
    }

    &__content_input{
      display: flex;
      flex-direction: column;
    }

    
  }
}


.bg-1{
  background-color: $variables-1;
}
.bg-2{
  background-color: $variables-2;
} 