.nombreinicial{

  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 5rem;
  
  &__container{
      text-align: center;
  }

  &__logo{
  width: 295px;
  }
  &__inputtext{
    font-family: 'Futura Std Medium';
    display: flex;
    width: 100%;
    color: #000;
    border: none;
    outline: none;
 
    
  }
  &__input {
    display: flex;
    flex-direction: row;
    padding: 10px 16px;
    flex: 1;
    margin: 15px 0;
    background: #FFFFFF;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    color: #888;
    
  }

  &__subtitle {
    color : $azul-crush;
    font-size: 1.1rem;
    padding-bottom: 1em ;
    font-family: 'Futura Std Heavy';
    width: 100%;
    margin-bottom: 2rem;
  }

  &__grid_content{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 1.2em;
    margin: 2.5em 0;
    margin-top: 0;
  }
  &__reto{
    color: $azul-crush;
    font-family: 'Futura Std Light';
  }
  &__card{
    background-color: $white;
    border-radius: 1em;
    padding: 1em 5em;
    &_flex{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      
      h3{
        margin: 0;
        padding: 0;
      }
    }
  }


  &__title {
    width: 100%;
    color : $gris
  }
  &__title2 {
    width: 100%;
    color : $gris;
    margin-top: 3rem;
  }

  &__button {
    @include button($fucsia-crush, $white);  
    margin-bottom: 2rem;
  }

  &__button:hover{
    box-shadow: rgba(0, 0, 0, 0.15) 0px -0.15rem 0px 0px inset;
    transform: translateY(1px);
  }

  @media (max-width : 580px ){
    &__input {
     
     width: 100%;
     padding: 0;
     margin: 0 auto;
     height: auto;
    }

    &__label{
      align-self: auto
    }

    
  }
}

