.ideacionscrumper{
  
  &__wrapper{
   display: flex;
   justify-content: center;
   align-items: center;



      h2 {
        text-align: left;
      }
  }
  &__text{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    h3{
      font-size: 24px;
    }
    h2{
      font-size: 20px;
    }
  }
  &__container{
      text-align: center;
  }
  &__error{
    color: $fucsia-crush;
    text-decoration: none;
  }

  &__logo{
    width: 26rem;
  }
  &__inputtext{
    font-family: 'Futura Std Medium';
    display: flex;
    max-width: 500px;
    min-width: 300px;
    width: 100%;
    color: #000;
    border: none;
    outline: none;
 
    
  }

  &__textnew{
    color: $blacked;
    background-color: $magente-light-2;
    border-radius: 1em;
    line-height: normal;
    max-width:500px;
    padding: 0.2em 6em;
    margin: 0 auto;
    p{
      font-size: 20px;
      font-family: 'Futura Std Bold';
    }
  }
  &__input {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin: 15px 0;
    background: #FFFFFF;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    color: #888;
    
  }

  &__subtitle {
    color : $magenta;
    font-size: 24px;
    font-family: 'Futura Std Light';
    text-align: center;
    width: 100%;
      b{
        
        font-weight: bold;
        font-family: 'Futura Std Bold';
      }
  }

  &__grid_content{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 1.2em;
    margin: 2.5em 0;
  }
  &__reto{
    color: $azul-crush;
    font-family: 'Futura Std Light';
  }
  &__card{
    background-color: $white;
    border-radius: 1em;
    padding: 1em 5em;
    &_flex{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      
      h3{
        margin: 0;
        padding: 0;
      }
    }
  }

  &__input {
    display: flex;
    flex-direction:  column;
    background: $white;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    color: #888;
    margin: 1rem 0;

    ::placeholder{
      color: $gris;
      font-family: 'Futura Std Heavy';
      letter-spacing: 0.5px;
      font-size: 16px;
      padding: 0.8em 0 0 0em;
      }

      p{
        color: $magenta-crush;
        font-family: 'Futura Std Bold';
        float: right;
        font-size: 16px;
        
      }
    
  }
  &__label{
    font-size: 17px;
    line-height: 17px;
    font-family: 'Futura Std Bold';
    margin-bottom: 10px;
    color: $magenta-ligh-crush;
    align-self: baseline;
  }
  &__textarea{
    font-size: 14px;
    line-height: 16px;
    font-family: 'Futura Std Bold';
    margin-bottom: 10px;
    border: none;
    resize: none;
    
  }
  &__title_head{
    color : $gris;
    font-family: 'Futura Std Bold';
  }

  &__title {

    color: $blacked;
    font-family: 'Futura Std Light';


    b{
      font-weight: bold;
      font-family: 'Futura Std Bold';

    }
    
  }

  &__button {
    @include button($fucsia-crush, $white);
  }
  &__content_button{
    display: block;
    width: 100%;
    padding: 0 6em 0 0;
    float: right;
    margin: 3em  0 1em 0;
  }

  &__change{
    background-color: $variables-1;
    padding: 0.5em;
    color: #794608;
    border-radius: 0.5em;
    font-size: 19px;
    font-weight: bold;
    width: 255px;
    height: 45px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.15) 0px -0.35rem 0px 0px inset;
    box-sizing: border-box;
    margin: 0.5em;
    float: right;


   
  }

  &__button:hover, &__change:hover{
    box-shadow: rgba(0, 0, 0, 0.15) 0px -0.15rem 0px 0px inset;
    transform: translateY(1px);
  }

  @media (max-width : 580px ){
    &__input {
     
     width: 100%;
     padding: 0;
     margin: 0 auto;
     height: auto;
    }

    &__label{
      align-self: auto
    }

    
  }
}

