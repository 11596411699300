.home{

  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &__container{
      text-align: center;
  }
  &__logo_complete{
    transform: translateY(50%);
    animation: logo 1.5s ease-out 1s forwards;
  }
  &__logo{
    width: 26rem;
  }

  &__subtitle {
    color : $azul-crush;
    font-size: 1.1rem;
    padding-bottom: 1em ;
    font-family: 'Futura Std Heavy';
  }
  &__text {
    color:#000;
    text-align: center;
    margin: 10px 0 65px 0;
    opacity: 0;
    animation: text 1s ease-out 1.5s forwards;
  }

  &__button {
    @include button($fucsia-crush, $white);   
  }

  &__button:hover{
    box-shadow: rgba(0, 0, 0, 0.15) 0px -0.15rem 0px 0px inset;
    transform: translateY(1px);
  }
}

@keyframes logo {
  0%{
    transform: translateY(50%);
    opacity: 1;
  }
  20%{
    transform: translateY(0%);
    opacity: 1;
  }
  90%{
    transform: translateY(0%);
    opacity: 1;
  }
  100%{
    transform: translateY(0%);
    opacity: 0;
  }
}
@keyframes text {
  0%{
    opacity: 0;
  }
  20%{
    opacity: 1;
  }
  80%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}