.ideacion__header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  h2{
    padding-right: 5em;
    margin-top: 2em;
    font-weight: 800;
  }
  .time{
    display: flex;
    margin-right: 2em;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    img{
      width: 40px;
      height: 40px;
      padding-right: 0.5em;
      font-weight: 800;
    }

    p {
      color: $magenta-ligh-crush;
      font-weight: 600;
      font-size: x-large;
      font-family: 'Futura Std Bold';


    }
  }


}

.score{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -3rem;

  h1{
    font-family: 'Futura Std Bold';
    font-weight: 800;
    color: $magenta-ligh-crush;
  }

  h2{
    color: $gris;
    font-family: 'Futura Std Bold';
    font-weight: 700;
  }

  &__ideas {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 3em;
    p {
      color: $blacked;
      font-family: 'Futura Std Bold';
      font-weight: 700;
      font-size: 48px;
      margin-bottom:0 ;
      margin-top: 0;
      text-align: center;
    }

    img{
      width: 48px;
      height: 48px;
    }
  }
  &__container{
      text-align: center;
    
  }
  &__nombres{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;


  }
  &__content_puestos {
    display: flex;
    align-items: flex-end;
    .score__puesto3 p{
      margin-left: 3rem;
    }
    .score__puesto2 p{
      margin-left: -1rem;
    }
    p{
      font-family: 'Futura Std Bold';
      color: $azul-crush;
      font-size: 24px;
      padding-bottom:0;
    }
    img {
      padding: 0;
      margin: 0 -3rem;
      position: relative;
      z-index: -1;
    }
    .primero{
      z-index: 1;
      margin-bottom: -3px;
    }
  }
  
  &__logo{
    width: 26rem;
  }
  

  &__input {
    display: flex;
    flex-direction: row;
    padding: 0px 16px;
    flex: 1;
    margin: 15px 0;
    background: #FFFFFF;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    color: #888;
    
  }

  

  &__text{
    margin: 0.2em 0 4em 0 ;
    padding: 0;
  }

  

  &__button {
    @include button($azul-crush, $white);
      }
  &__buttons_content{
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em 0;
    align-items: center;
    a{
      text-decoration: none;
    }
  }

  &__return{
    background-color: transparent;
    border: none;
    color : $fucsia-crush;
    font-family: 'Futura Std Bold';

    img {
      width: 0.5rem;
    }
  }
  &__content_button{
    display: block;
    width: 100%;
    padding: 0 6em 0 0;
    float: right;
    margin: 3em  0 1em 0;
  }


  &__button:hover{
    box-shadow: rgba(0, 0, 0, 0.15) 0px -0.15rem 0px 0px inset;
    transform: translateY(1px);
  }

  @media (max-width : 580px ){
    &__input {
     
     width: 100%;
     padding: 0;
     margin: 0 auto;
     height: auto;
    }

    &__label{
      align-self: auto
    }

    
  }
}

