.tiempo{

  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &__container{
      text-align: center;
  }

  &__logo{
    width: 26rem;
  }



  &__subtitle {
    color : $azul-crush;
    font-size: 1.1rem;
    padding-bottom: 1em ;
    font-family: 'Futura Std Heavy';
    width: 100%;
  }

  &__change{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 3em 0;
  }


  &__title {
    color : $gris;
    font-size: 24px;
    
  }
  &__input{
    background-color: $magente-light-2;
    padding: 0.5em;
    border-radius: 0.5em;
    color: $azul-crush;
    width: 50px;
    font-size: 22px;
    font-family: 'Futura Std Bold';
    font-weight: bold;
    box-shadow: $fucsia-light-2 0px -0.35rem 0px 0px inset;
    box-sizing: border-box;
    margin: 0.5em;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select:none;
  }

  &__number{
    background-color: $white;
    padding: 0em 2em;
    margin: 0;
    display: inline-flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 0.5em;
    
    p {
      padding: 0;
      margin: 0;
      font-weight: bolder;
      color: $time-number;
      font-family: 'Futura Std Bold';
      font-size: 23px;

    }
  }
  &__button {
    @include button($fucsia-crush, $white);  
  }

  &__button:hover, &__input:hover{
    box-shadow: rgba(0, 0, 0, 0.15) 0px -0.15rem 0px 0px inset;
    transform: translateY(1px);
  }

  @media (max-width : 580px ){
    &__input {
     
     width: 100%;
     padding: 0;
     margin: 0 auto;
     height: auto;
    }

    &__label{
      align-self: auto
    }

    
  }
}

