.compartir{

  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &__container{
      text-align: center;
  }

  &__logo{
    width: 26rem;
  }



  &__subtitle {
    color : $azul-crush;
    font-size: 1.1rem;
    padding-bottom: 1em ;
    font-family: 'Futura Std Heavy';
    width: 100%;
  }

  &__change{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 3em 0;
  }


  &__title {
    width: 100%;
    color : $gris;
  }
  

 
  &__button { 
    @include button($fucsia-crush, $white);
  }
  &__enlace_container{
    background-color: $white;
    border-radius: 1em;
    margin: 3em  auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__text{
    display: flex;
    flex-direction:  column;
    
  
    p{
      padding: 0;
      margin: 0;
      max-width: 300px;
      text-align: left;
      font-family: 'Futura Std Heavy';
      color: $magenta-ligh-crush;
      padding: 0.5em 0;
      
    }

  }
  
  &__content{
    background-color: white;
    padding: 0.5rem 2rem 2rem 2rem;
    margin: 0 auto;
    width: 100%;
    border-radius: 10px;
    
  }
  
  &__url{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    span {
      font-family: 'Futura Std Bold';
      font-size: 16px;
      color: $time-number;
      padding-right: 25px;
      
    }
    button{
      background: transparent;
      cursor: pointer;
      border: 0;
      img{
  
        width: 25px;
        height: auto;
      }
    }
    
  }
  &__button:hover{
    box-shadow: rgba(0, 0, 0, 0.15) 0px -0.15rem 0px 0px inset;
    transform: translateY(1px);
  }

  @media (max-width : 580px ){
    &__input {
     
     width: 100%;
     padding: 0;
     margin: 0 auto;
     height: auto;
    }

    &__label{
      align-self: auto
    }

    
  }
}

