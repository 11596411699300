body{
  font-family: 'Futura Std Medium';
  background-color: $background-color;
  background-color: $white;
  background-image: url(../../assets/img/fondo.svg);
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

*{
  box-sizing: border-box;
}
input, textarea{
  text-transform: none !important;
}
.logo{
  width: 295px;
}

input:focus, textarea:focus {
  outline:none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

::-webkit-scrollbar  {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track{
  border-radius: 11px;
  background-color: white;
}

  /* Handle */
::-webkit-scrollbar-thumb{
  border-radius: 11px;
  background-color: #b8babb;
}

button:disabled{
  background: $disable-button;
  color: $gris;
  cursor: default;
}