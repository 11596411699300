.proposito{

  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 5rem;
  
  &__container{
      text-align: center;
  }

  &__logo{
    width: 26rem;
  }

  &__subtitle {
    color : $azul-crush;
    font-size: 1.1rem;
    padding-bottom: 1em ;
    font-family: 'Futura Std Heavy';
    width: 100%;
  }

  &__grid_content{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1.2em;
    margin: 2.5em 0;
  }
  &__card{
    background-color: $white;
    border-radius: 1em;
    padding: 1em 5em;
    padding-bottom: 3rem;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.173);
    display: block;
    position: relative;
    cursor: pointer;
    &_magic{
      width: 24px;
      height: 24px;
      opacity: 0;
      position: absolute;
      bottom: 1rem;
      left: 1rem;
    }
    &_flex{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      img{
        margin-bottom: 1rem;
        width: 72px;
        height: 72px;
      }
      h3{
        margin: 0;
        padding: 0;
      }
    }
  }
  &__input{
    display: none;
    &:checked{
      &+label{
        border: 3px $fucsia-crush solid;
        background: #FFF3F2;
        .proposito__card_magic{
          opacity: 1;
        }
      }
    }
  }


  &__title {
    width: 100%;
    color : $fucsia-light
  }

  &__button {
    @include button($fucsia-crush, $white);  
  }

  &__button:hover{
    box-shadow: rgba(0, 0, 0, 0.15) 0px -0.15rem 0px 0px inset;
    transform: translateY(1px);
  }

  @media (max-width : 580px ){
    &__input {
     
     width: 100%;
     padding: 0;
     margin: 0 auto;
     height: auto;
    }

    &__label{
      align-self: auto
    }

    
  }
}

