@mixin button($color, $textColor) {
    background-color: $color;
    padding: 0.5em;
    border-radius: 7px;
    color: $textColor;
    font-size: 19px;
    font-weight: bold;
    width: 255px;
    height: 50px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.15) 0px -0.35rem 0px 0px inset;
    box-sizing: border-box;
    margin: 0.5em;
    cursor: pointer;
}