.Chip{
    position: relative;
    width: fit-content;
    &__content{
        min-width: 200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #DDFEFD;
        border-radius: 10px;
        color: #103F74;
        font-weight: bold;
        padding: 8px 10px;
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
        button{
            background: transparent;
            border: 0;
            cursor: pointer;
        }
        object{
            pointer-events: none;
        }
    }
}